// Customizable Area Start
import * as Yup from "yup";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { parseTableData } from "../../../components/src/Utils/parseData";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export type token = string | null;

interface S {
  payment_date: string | null;
  payment_received: number | null;
  token: token;
  company_name: string | null;
  account_id: number | null;
  isLoad: boolean;
  isBalanceCredit: boolean;
  activeBtn: boolean;
  balance: number | null;
  tableData: any[];
  fieldData: any;
  paymentType: string | null;
  isPaymentCredit : boolean;
  isSnackbarOpen : boolean;
  snackBarMessage:string;
  oldestInvoiceDate:string;
}

interface SS {
  id: any;
}

export default class ProcessPaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPaymentDetailsAPI: string = "";
  createPaymentAPI: string = "";
  updatePaymentAPI: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      payment_date: "",
      payment_received: null,
      account_id: null,
      isLoad: false,
      balance: 0,
      token: "",
      activeBtn: false,
      isBalanceCredit: false,
      company_name: "",
      tableData: [],
      fieldData: {},
      paymentType:'payment',
      isPaymentCredit:false,
      isSnackbarOpen:false,
      snackBarMessage:'',
      oldestInvoiceDate:''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentWillMount(): any {
    if (localStorage.getItem("auth_token")) {
      const path: any[] = window.location.pathname.split("/");
      const token = localStorage.getItem("auth_token");
      const id = path[path.length - 1];
      this.setState({
        token: token,
        account_id: Number(id),
        isLoad: true,
      });
      this.getPaymentDetailsAPI = this.apiCallPayment(
        token,
        "GET",
        `bx_block_payments/payments_receiveds/get_payments_received?account_id=${id}`
      );
    } else {
      this.props.navigation.navigate("EmailLogin");
    }
  }

  handleTableField = (e: any, id: any) => {
    const { value } = e.target;
    this.setState({ fieldData: { payment_received_id: id, payment_received: value } });
  }

  handleEditField = (obj: any) => {
    const { id, payment_received } = obj;
    this.setState({ fieldData: { payment_received_id: id, payment_received: payment_received } });
  }
  
  handleSnackbarClose = () => { this.setState({isSnackbarOpen:false}) }

  createPayment = (values: any) => {
    const { token, isBalanceCredit, balance } = this.state;
    if (isBalanceCredit && balance && values.payment_type === 'credit' && values.payment_received > balance) {
      this.setState({ isSnackbarOpen: true , snackBarMessage: 'Credit value can not be more than Outstanding balance' })
      return true
    }
    if (!isBalanceCredit && values.payment_type === "credit") {
      this.setState({ isSnackbarOpen: true, snackBarMessage: "Can't add a credit return if the balance is 0" })
      return true
    }
    this.setState({ isLoad: true, paymentType: values.payment_type });
    this.createPaymentAPI = this.apiCallPayment(
      token,
      "POST",
      `bx_block_payments/payments_receiveds`,
      JSON.stringify(values)
    );
  }

  updatePayment = (paymentType: any) => {
    const { token, fieldData, balance, isBalanceCredit } = this.state;
    if (paymentType === "credit" && balance && fieldData.payment_received > balance) {
      this.setState({ isSnackbarOpen: true, snackBarMessage: 'Credit value can not be more than Outstanding balance' })
      return true
    }
    if (!isBalanceCredit && paymentType === "credit") {
      this.setState({ isSnackbarOpen: true, snackBarMessage: "Can't add a credit return if the balance is 0" })
      return true
    }
    const data = {
      ...fieldData,
      account_id: this.state.account_id,
      payment_type: paymentType,
    }
    if (fieldData?.payment_received) {
      this.setState({ isLoad: true });
      this.updatePaymentAPI = this.apiCallPayment(
        token,
        "PUT",
        `bx_block_payments/payments_receiveds/update_payments_received`,
        JSON.stringify(data)
      );
    }
  }

  updatePaymentRes = (res: any) => {
    if (res.data.id) {
      const { tableData } = this.state;
      const newData = [...tableData];
      const obj = parseTableData([res.data])[0];
      const index = tableData.findIndex(item => item?.id === obj?.id);
      if (index !== -1) {newData[index] = obj}
      const oldestDate = res.meta?.latest_invoice_po_date;
      const balance = res.meta?.outstanding_account_balance;
      const outstandingStatus = res.meta?.outstanding_status;
      this.setState({ isLoad: false, fieldData: {}, tableData: newData, oldestInvoiceDate: oldestDate,balance: balance ?? 0,isBalanceCredit: outstandingStatus, });
    } else {
      this.setState({ isLoad: false })
    }
  }

  createPaymentRes = (res: any) => {
    if (res.data) {
      const data4 = parseTableData(res.data)
      const balance = res?.meta?.outstanding_account_balance;
      const isBalanceCredit = res?.meta.outstanding_status;
      const oldestDate = res.meta.latest_invoice_po_date;
      this.setState({
        activeBtn: false,
        isLoad: false,
        fieldData:{},
        payment_date: "",
        payment_received: 0,
        tableData: data4,
        balance: balance ?? 0,
        isBalanceCredit: isBalanceCredit,
        oldestInvoiceDate:oldestDate
      },
      () => {
        const { token, account_id } = this.state;
        if (token && account_id) {
          this.getPaymentDetailsAPI = this.apiCallPayment(
            token,
            "GET",
            `bx_block_payments/payments_receiveds/get_payments_received?account_id=${account_id}`
          );
        }
      }
    )
    } else {
      this.setState({ isLoad: false })
    }
  }

  paymentDetailsRes = (res: any) => {
    if (res.data || res?.meta) {
      const data = parseTableData(res.data)
      const companyName = res?.meta?.company_name;
      const balance = res?.meta?.outstanding_account_balance;
      const isBalanceCredit= res?.meta.outstanding_status;
      const oldestInvoiceDate= res.meta.latest_invoice_po_date;
      this.setState({
        isLoad: false,
        tableData: data,
        company_name: companyName ?? "",
        balance: balance ?? 0,
        isBalanceCredit: isBalanceCredit,
        oldestInvoiceDate: oldestInvoiceDate
      })
    } else {
      this.setState({
        isLoad: false,
      })
    }
  }

  validationPaymentSchema = Yup.object().shape({
    payment_received: Yup.number()
    .min(0.001, 'Amount must be greater than 0')
      .nullable()
      .required("Payment is required"),
    payment_date: Yup.string()
      .nullable()
      .required("Payment date is required"),
  });

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId5 = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson5 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId5 && responseJson5) {
        this.receivePaymentApi(apiRequestCallId5, responseJson5);
      }
    }
  }

  receivePaymentApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getPaymentDetailsAPI) {
      this.paymentDetailsRes(responseJson);
    }
    if (apiRequestCallId === this.createPaymentAPI) {
      this.createPaymentRes(responseJson);
    }
    if (apiRequestCallId === this.updatePaymentAPI) {
      this.updatePaymentRes(responseJson);
    }
  };

  handleRadioCheck = (paymentTypeValue:any) =>{
    paymentTypeValue === "credit" ? this.setState({isPaymentCredit : true}) : this.setState({isPaymentCredit : false})
  }

  apiCallPayment = (
    token: token,
    method: string,
    endPoint: string,
    body?: any
  ) => {
    const paymentHeader = {
      token: token,
      "Content-Type": "application/json",
    };

    const requestMessage4 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const bodyPaymentHeader = {
      token: token,
      "Content-Type": "application/json",
    };

    if (body) {
      requestMessage4.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(bodyPaymentHeader)
      );

      requestMessage4.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    } else {
      requestMessage4.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(paymentHeader)
      );
    }
    requestMessage4.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage4.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    runEngine.sendMessage(requestMessage4.id, requestMessage4);

    return requestMessage4.messageId;
  };


}
// Customizable Area End
