// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { Formik } from "formik";

import InvoiceReviewController, { Props } from "./InvoiceReviewController.web";
import { styles } from "./InvoiceReviewStyles.web";
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";
import ReviewRejectModalWeb from "../../../components/src/Modal/ReviewRejectModal.web";
import { convertIntoCurrency, downloadFiles, onKeyDown } from "../../../components/src/Utils/utils";
import ReviewApproveModalWeb from "../../../components/src/Modal/ReviewApproveModal.web";
import CustomizedStepperWeb from "../../../components/src/Stepper/CustomizedStepper.web";
import { invoicePO_steps } from "../../../components/src/Utils/constant";
import CommonButtonWeb from "../../../components/src/Button/CommonButton.web";
import InvoiceBalanceTable from "../../../components/src/Table/InvoiceBalanceTable.web";
import {
  parseInvoiceBalanceData1,
  parseInvoiceBalanceData2,
} from "../../../components/src/Utils/parseData";
import NumberFormatCustom from "../../../components/src/CustomTextField/NumberFormatCustom"
import DateTextfieldWeb from "../../../components/src/Datepicker/DateTextfield.web";
import { invoiceReviewSchema } from "../../../components/src/Utils/validations";
const fileIcon = require("../../../components/src/Assets/file.png");
const downloadIcon = require("./assets");

class InvoiceReview extends InvoiceReviewController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {
      rejectOpen1,
      approveOpen1,
      comment1,
      invoiceDocuments,
      companyName1,
      requestId,
      factor_limit,
      isBalancetable,
      activeStep,
      createdAtDate1,
      fields,
      attributes,
      isLoading1,
    } = this.state;
    const {
      payment_terms,
      disbursement_date,
      amount_disbursed,
      invoice_amount,
      invoice_date,
      invoice_number,
      buyer_name,
    } = fields;

    const renderButtons = (invoiceDocuments: any) => {
      const disabled = invoiceDocuments.every(
        (items: any) => items.status === "reviewed"
      );
      if (activeStep === 0) {
        return (
          <div className="btnContainerLeft">
            <Button
              className={disabled ? "leftbtn" : "leftbtn disabled"}
              data-test-id={`handleApproveClick-3`}
              onClick={() => this.setState({ rejectOpen1: true })}
              disabled={!disabled}
            >
              Reject
            </Button>
            <Button
              className={disabled ? "leftbtn approveBtn1" : "leftbtn disabled"}
              data-test-id={`handleApproveClick-2`}
              onClick={() => this.setState({ approveOpen1: true })}
              disabled={!disabled}
            >
              Approve
            </Button>
          </div>
        );
      }
    };

    const renderDisbursement = (
      values: any,
      handleChange: any,
      errors: any,
      touched: any,
      handleBlur: any,
    ) => {
      const isDisable = isBalancetable && values.payment_terms === initialValues.payment_terms
      const factorLimit = isBalancetable ? factor_limit : factor_limit - values.amount_disbursed

      return (
        activeStep >= 1 && (
          <>
            <Typography variant="h3" className={classes.headingTitle}>
              Disbursement :
            </Typography>
            <Grid container spacing={3} direction={"column"}>
              <Grid item sm={12} md={12}>
                <InputLabel className={classes.inputLabel}>
                  Amount disbursed *:
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="amount_disbursed"
                  value={isBalancetable ? convertIntoCurrency(values.amount_disbursed) : values.amount_disbursed}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      min: 0,
                      style: {
                        MozAppearance: "textfield"
                      }
                    }
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  className={classes.inputField}
                  error={this.handleInvoiceErrors(
                    errors.amount_disbursed,
                    touched.amount_disbursed
                  )}
                  disabled={isBalancetable}
                />

                <FormHelperText data-test-id={'FormHelperText'} className={classes.helperInvoiceText}>{errors.amount_disbursed ? this.handleInvoiceHelperText2(errors.amount_disbursed) : `Note: J$ ${convertIntoCurrency(factorLimit)} remaining until Factoring Limit reached`}</FormHelperText>
              </Grid>
              <Grid container sm={12} lg={12} md={12} className={classes.disbursementDateProvider}>
                <InputLabel className={classes.inputLabel}>
                  Disbursement Date*:
                </InputLabel>
                <DateTextfieldWeb
                  value={values.disbursement_date}
                  min={new Date(invoice_date)}
                  handleChange={handleChange}
                  name="disbursement_date"
                  helperText={this.handleInvoiceHelperText(
                    errors.disbursement_date,
                    touched.disbursement_date
                  )}
                  error={this.handleInvoiceErrors(
                    errors.disbursement_date,
                    touched.disbursement_date
                  )}

                  disabled={isBalancetable}
                  width="100%"
                /> 

                {!isBalancetable && !this.showNoteOnCompDate(values.disbursement_date, values.payment_terms) && (<FormHelperText data-test-id={'FormHelperText'} className={classes.helperInvoiceText}>Note: Invoice/PO is past due. Risk of incorrect past due calculation.</FormHelperText>)}

              </Grid>
            </Grid>
            <div className="btnContainerLeft">
              <CommonButtonWeb btnText="Save" type="submit" disabled={isDisable || this.state.handle_invoice}  />
            </div>
          </>
        )
      );
    };

    const initialValues = {
      payment_terms: payment_terms,
      disbursement_date: disbursement_date,
      amount_disbursed: amount_disbursed,
      invoice_detail_id: requestId,
    };

    const schema = invoiceReviewSchema(this.state)

    return (
      <div className={classes.invoiceDiv}>
        {isLoading1 && <CustomLoader open={isLoading1} />}
        <Typography className="maintitle">Invoice Details</Typography>
        {!isBalancetable && <CustomizedStepperWeb steps={invoicePO_steps} activeStep={activeStep} />}
        <Box className="subTitleContainer1">
          <Typography variant="h3" className="title1">
            {companyName1}
          </Typography>
          <Typography variant="subtitle1" className="date1">
            {createdAtDate1}
          </Typography>
        </Box>
        <Formik
          enableReinitialize={true}
          validateOnBlur={true}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => this.updateDisbursement(values)}
        >
          {({ errors, values, handleSubmit, handleChange, touched, handleBlur }) => {
            return (
              <form onSubmit={handleSubmit} onKeyDown={onKeyDown} className={classes.formWidthProvider}>
                <Grid
                  container
                  spacing={3}
                  direction={"row"}
                  style={{ width: "auto" }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel className={classes.inputLabel}>
                      Buyer Name*:
                    </InputLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      name="buyer_name"
                      value={buyer_name}
                      fullWidth
                      className={classes.inputField}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel className={classes.inputLabel}>
                      Payment Terms (In Days)*:
                    </InputLabel>
                    <TextField
                      data-test-id='payment-field'
                      type="number"
                      variant="outlined"
                      fullWidth
                      name="payment_terms"
                      onChange={handleChange}
                      value={values.payment_terms}
                      className={classes.inputField}
                      disabled={this.state.isPaymentTermsEditable}
                      inputProps={{
                        min: 0,
                        style: {
                          MozAppearance: "textfield"
                        }
                      }}
                      error={this.handleInvoiceErrors(
                        errors.payment_terms,
                        touched.payment_terms
                      )}
                      helperText={this.getPaymentTermsHelperText(values, errors, touched, this.handleInvoiceHelperText)}

                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputLabel className={classes.inputLabel}>
                      Invoice Number*:
                    </InputLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      name="invoice_number"
                      value={invoice_number}
                      className={classes.inputField}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputLabel className={classes.inputLabel}>
                      Invoice Date*:
                    </InputLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      name="invoice_date"
                      value={invoice_date}
                      className={classes.inputField}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputLabel className={classes.inputLabel}>
                      Invoice Amount*:
                    </InputLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      name="invoice_amount"
                      value={convertIntoCurrency(invoice_amount)}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                      className={classes.inputField}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.bottomDiv}>
                  <Grid item md={6} lg={6} xs={12} sm={12}>
                    <div className={classes.collapseContainer}>
                      <Typography variant="h3" className="collapseTitle">
                        Find the attached file below:
                      </Typography>
                      {invoiceDocuments.map((item, index) => {
                        const isReviewed = item.status === "reviewed";
                        return (
                          <div className="collapse" key={item.id}>
                            <Typography variant="body1" gutterBottom>
                              <img src={fileIcon} /> {item.name}
                            </Typography>
                            <div className="dwnContainer">
                              <img
                                src={downloadIcon.Download}
                                style={{ cursor: "pointer" }}
                                data-test-id={`downloadFiles2-${index}-2`}
                                onClick={() => { downloadFiles(item.url); !isReviewed && this.handleInvoiceReview(item.id) }}
                                alt="Download"
                              />
                              <Button
                                data-test-id={`reviewedBtns2-${index}-2`}
                                className={`btn ${isReviewed ? "reviewedBtn" : ""
                                  }`}
                              >
                                {item.status}
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {renderDisbursement(values, handleChange, errors, touched, handleBlur)}
                    {renderButtons(invoiceDocuments)}
                  </Grid>
                  {isBalancetable && (
                    <Grid item md={6} lg={6} xs={12} sm={12}>
                      <Box className={classes.rightBtmDiv}>
                        <div className={classes.rightBtmHeader}>
                          <h4 className="rightBtmTitle">
                            Remaining Balance Due on Invoice
                          </h4>
                          <CommonButtonWeb
                            btnText={`J$ ${convertIntoCurrency(attributes.remaining_balance_due_on_invoice)}`}
                            color="#2E2A40"
                            backgroundColor="#FED86C"
                            width="auto"
                            classNames="rightBtmBtn"
                          />
                        </div>
                        <InvoiceBalanceTable
                          data={parseInvoiceBalanceData1(attributes)}
                        />
                        <InvoiceBalanceTable
                          data={parseInvoiceBalanceData2(attributes)}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </form>
            );
          }}
        </Formik>
        <ReviewApproveModalWeb
          open={approveOpen1}
          handleSubmitModal={this.handleApproveClickInvoice}
          handleModelClose={this.handleModalCloseInvoice}
          widthToAdd="85%"
        />
        <ReviewRejectModalWeb
          open={rejectOpen1}
          comment={comment1}
          handleChange={this.handleRejectInputChangeInvoice}
          handleSubmitModal={this.rejectRequestInvoice}
          handleModelClose={this.handleModalCloseInvoice}
          widthToAdd="85%"
        />
      </div>
    );
  }
}

export default withStyles(styles)(InvoiceReview);
// Customizable Area End
